import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import "../stylling/loader.css";

const Loader = ({ onAnimationComplete }) => {
  const loaderRef = useRef();
  const greenRef = useRef();
  const wordsRef = useRef([]);

  useEffect(() => {
    const timeline = gsap.timeline({ onComplete: onAnimationComplete });

    wordsRef.current.forEach((word, index) => {
      timeline.fromTo(
        word,
        { x: -100, opacity: 0 },
        { x: 0, opacity: 0.5, duration: 1, ease: "power2.out", }
      );
    });

    timeline.to(greenRef.current, {
      yPercent: -100,
      duration: 0.8,
      ease: "power2.out",
    });

    timeline.to(loaderRef.current, {
      yPercent: -100,
      duration: 1,
      ease: "power2.out",
    }, "-=0.7");

  }, [onAnimationComplete]);

  return (
    <div ref={loaderRef} className="main">
      <div className="loader">
        <div className="topheading">
          <h5 className="reveal">Design Portfolio </h5>
          <h5 className="reveal"> &copy; 2024</h5>
        </div>
        <h1 className="reveal text-center">
          Welcome to <p></p>
          {["Portfolio"].map((word, index) => (
            <span className="ml-4" key={index} ref={(el) => (wordsRef.current[index + 2] = el)} style={{ display: 'inline-block' }}>
              {word}{" "}
            </span>
          ))}
        </h1>
      </div>
      <div ref={greenRef} className="green"></div>
      <div className="loading-animation">
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
      </div>
    </div>
  );
};

export default Loader;
