import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { BsFillRocketFill } from "react-icons/bs";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

// Register the ScrollToPlugin
gsap.registerPlugin(ScrollToPlugin);

function Scrollontop() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      window.pageYOffset > 100 ? setVisible(true) : setVisible(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTo = () => {
    gsap.to(window, {
      duration: 1.5,
      scrollTo: { y: 0, autoKill: false },
      ease: "power2.inOut",
    });
  };

  return (
    <Div>
      <button
        onClick={handleScrollTo}
        className={`${visible ? "block" : "none"}`}
      >
        <BsFillRocketFill className="text-[6rem] " />
      </button>
    </Div>
  );
}

const Div = styled.div`
  max-width: 120vw;
  .none {
    opacity: 0;
    visibility: hidden;
  }
  button {
    cursor: pointer;
    position: fixed;
    bottom: 30px;
    left: 18px;
    background-color: var(--primary-color);
    padding: 0.6rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s ease-in-out;
    z-index: 1;
    svg {
      color: #ffbd00;
      font-size: 1.5rem;
    }
  }
`;

export default Scrollontop;
